.container {
  display: flex;
  align-items: flex-start;
  margin: 0.5rem 0 1rem;
  padding: 0.5rem 1rem;
  background: #FFFBE6;
  border: 1px solid #FFE58F;
  border-radius: 2px;
}
.text {
  margin: -0.25rem 0 0 0.5rem;
  font-size: 0.8rem;
  color: rgba(0, 0, 0, 0.85);
}

@hack: true; @import "/codebuild/output/src351496252/src/github.com/Electric-Hydrogen/lifteware-web/theme.less";