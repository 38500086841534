.paragraph {
  margin-bottom: 0.5rem;
}
.input {
  margin-bottom: 1rem;
}
.searchIcon {
  color: #7e7e7e;
}
.mapContainer {
  width: 100%;
  aspect-ratio: 4/2;
  border: 1px solid #D9D9D9;
  border-radius: 20px;
}

@hack: true; @import "/codebuild/output/src351496252/src/github.com/Electric-Hydrogen/lifteware-web/theme.less";