.headerContainer {
  padding: 16px 24px;
  border-bottom: 1px solid #f0f0f0;
  height: 65px;
}

.mainContainer {
  padding: 24px;
}

.titleContainer {
  display: flex;
  align-items: center;
  justify-content: space-between;
  .title {
    display: flex;
    gap: 12px;
    flex-direction: row;
    align-items: center;
    svg {
      width: 24px;
      height: 24px;
    }
    .tag {
      font-size: 12px;
      .sharedBy {
        color: @text-color-secondary;
      }
    }
  }
  .switchButtons {
    display: flex;
    align-items: center;
    button {
      border: 1px solid #d9d9d9;
      &:focus {
        color: initial;
        border-color: initial;
      }
      &.active {
        border-color: @teal;
        color: @teal;
        z-index: 1;
      }
      &.switchButtonLeft {
        border-top-right-radius: 0;
        border-bottom-right-radius: 0;
        margin-right: -1px;
      }
      &.switchButtonRight {
        border-top-left-radius: 0;
        border-bottom-left-radius: 0;
      }
    }
  }

  .mapIcon {
    width: 16px;
    height: 16px;
    transform: translateY(2px);
  }
}

.map {
  height: calc(100vh - 350px);
  margin-top: 1rem;
}

@hack: true; @import "/codebuild/output/src351496252/src/github.com/Electric-Hydrogen/lifteware-web/theme.less";