@import "/theme.less";

@header-height: 64px;

.sidebar {
  overflow: auto;
  position: fixed !important;
  height: calc(100vh - 48px);
  z-index: 1;
}
.content {
  background: @bg-light;
  transition: 0.2s;
  .contentContainer {
    height: 100%;
    margin: 24px;
    padding: 24px;
    padding-bottom: 0;
    margin-bottom: 0;
  }
}


.container {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.externalContainer {
  height: calc(100vh - @header-height - @layout-header-height - 6px);
  width: 100%;
}

.iframeContainer {
  height: 100%;
  padding: 0 6px;
  background-color: #f4f5f5;

  iframe {
    height: 100%;
    width: 100%;
    border: 0;
    margin: 0;
  }
}
.select {
  min-width: 18ch;
}

.toolbar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: @header-height;
  background-color: #f4f5f5;
  padding: 0 22px;
  padding-top: 20px;
  padding-bottom: 4px;

  :global(.ant-select-selector) {
    align-items: center;
    border: none !important;
    border-radius: 4px !important;
    box-shadow: 0px 1px 2px -2px rgba(0, 0, 0, 0.1), 0px 1px 6px rgba(0, 0, 0, 0.1);
    outline: none !important;
  }
}

.iconContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 32px;
  height: 32px;
  background: #ffffff;
  box-shadow: 0px 1px 2px -2px rgba(0, 0, 0, 0.1), 0px 1px 6px rgba(0, 0, 0, 0.1);
  border-radius: 4px;
  font-size: 24px;
  color: @teal;
  cursor: pointer;
}

:global {
  .ant-layout-sider > .ant-layout-sider-trigger {
    box-shadow: inset 0px 1px 0px #f0f0f0;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    svg {
      margin-right: 23px;
    }
  }

  .ant-layout-sider:not(.ant-layout-sider-collapsed) > .ant-layout-sider-trigger {
    svg {
      transform: rotate(180deg);
    }
  }
}

@toggle-width: 18px;
@toggle-height: 180px;

.toggleBar {
  position: absolute;
  top: calc(50% - @toggle-height / 2);
  right: -17px;
  cursor: pointer;
  z-index: -1;
  filter: drop-shadow(0 10px 15px rgba(0, 0, 0, 0.1))
}

@icon-size: 18px;
.toggleIcon {
  color: @eh2-green !important;
  cursor: pointer;
  position: absolute;
  font-size: @icon-size;
  right: -17px;
  top: calc(50% - @icon-size / 2);
}

@hack: true; @import "/codebuild/output/src351496252/src/github.com/Electric-Hydrogen/lifteware-web/theme.less";