.roleContainer {
  display: flex;
  flex-direction: column;
  padding: 0.6rem 0;

  .updateButton {
    width: fit-content;
    align-self: start;
  }
}

@hack: true; @import "/codebuild/output/src351496252/src/github.com/Electric-Hydrogen/lifteware-web/theme.less";