@import "/theme.less";

.upperContainer {
  display: flex;
  justify-content: space-between;
  margin-right: 120px;

  .titleContainer {
    display: flex;
    gap: 8px;
    align-items: center;
    font-size: 24px;
    font-weight: 500;
    padding: 16px 0;
  }
  .versionSelect {
    min-width: 30ch;
  }
}


.detailItem {
  display: flex;
  max-width: 1200px;
  padding-bottom: 8px;

  .detailLabel {
    font-weight: 500;
    min-width: 24ch;
  }
}

.ruleItemContainer {
  display: flex;
  flex-direction: column;

  .ruleItem {
    padding: 4px;
    border-radius: 4px;
    border: 1px solid rgba(0, 65, 102, 0.2);
  }
}
.ruleSeparator {
  align-self: center;
}

.plotContainer {
  display: flex;
  flex-direction: column;
  align-items: center;

  .timelineContainer {
    z-index: 10;
    :global(svg rect) {
      fill-opacity: 0.8 !important;
    }
    :global(svg text) {
      opacity: 0.8 !important;
      fill: @text-color;
    }
  }

  .timelineContainer > :global(svg) {
    overflow: visible;
  }

  .innerContainer {
    display: flex;
    flex-direction: column;
    gap: 2px;
    overflow: hidden;
    .select {
      margin-bottom: 16px;
      width: 18ch;
      align-self: flex-end;
    }
  }
}

@hack: true; @import "/codebuild/output/src351496252/src/github.com/Electric-Hydrogen/lifteware-web/theme.less";