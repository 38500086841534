@import "/theme.less";

.container {
  width: min(85%, 87.5rem);

  margin: 0 auto;
  display: flex;
  flex-direction: column;
  gap: 1.75rem;

  @media screen and (max-width: @screen-lg) {
    width: 100%;
  }
  @media screen and (max-width: @screen-md) {
    width: 100%;
    margin: 0.75rem;
  }
}

.signaturesContainer {
  display: flex;
  flex-direction: row;
  gap: 4rem;
  .signatureColumn {
    display: flex;
    flex-direction: column;
    gap: 0.75rem;
  }
  .signatureTextContainer > span {
    margin-left: 16px;
  }
  .signatureImageContainer {
    width: 15.625rem;
    .signatureImage {
      width: 100%;
    }
  }
}

.titleContainer {
  padding-top: 1.5rem;
}

.titleDivider {
  margin-top: 0.75rem;
}

.bold {
  font-weight: 500;
}

.buttonsContainer {
  display: flex;
  justify-content: space-between;
}

.backButton,
.archiveButton {
  width: min-content;
  :global(.anticon) {
    padding-right: 0.25rem;
  }
}

@hack: true; @import "/codebuild/output/src351496252/src/github.com/Electric-Hydrogen/lifteware-web/theme.less";