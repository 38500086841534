@import "/theme.less";

.list {
  max-height: 166px;
  overflow: auto;
  border: 1px solid #d9d9d9;
  border-radius: 4px;
  width: 100%;
  padding-top: 6px;
  padding-left: 9px;
  margin-top: 8px;

  .listItem {
    margin-bottom: 4px;
  }
}

.checkboxGroupContent {
  display: flex;
  flex-direction: column;
  gap: 6px;
}

.collapse {
  border-radius: 4px;
  border: 1px solid rgba(0, 65, 102, 0.2);
  background: white;

  .collapseTitleContainer {
    :global(.ant-typography-edit) {
      color: @teal;
    }
  }
}

@hack: true; @import "/codebuild/output/src351496252/src/github.com/Electric-Hydrogen/lifteware-web/theme.less";