.container {
  display: flex;
  align-items: center;
  gap: 10px;
  .title {
    font-size: 16px;
    font-weight: 500;
  }
  .iconContainer {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 32px;
    height: 32px;
    background: #ffffff;
    box-shadow: 0px 1px 2px -2px rgba(0, 0, 0, 0.1), 0px 1px 6px rgba(0, 0, 0, 0.1);
    border-radius: 4px;
    font-size: 24px;
    color: @teal;
    cursor: pointer;
  }
}

.dropdown {
  :global(.ant-dropdown-menu-item-selected:hover) {
    background-color: #e5f1f1 !important;
  }
  :global(.ant-dropdown-menu-item-disabled:hover) {
    cursor: default !important;
  }
}

.dropdownButton {
  display: flex;
  justify-content: flex-end;
  min-width: 16ch;
  border-radius: 4px;
  .dropdownButtonContent {
    width: 100%;
    justify-content: space-between;
  }
}

.dropdownItemContainer {
  display: flex;
  gap: 12px;

  .dropdownTagsContainer {
    display: flex;
    gap: 4px;
  }
}

@hack: true; @import "/codebuild/output/src351496252/src/github.com/Electric-Hydrogen/lifteware-web/theme.less";