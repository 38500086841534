.container {
  display: flex;
  height: min(75vh, 900px);

  .topContainer {
    display: flex;
    justify-content: space-between;
    font-weight: 500;
    font-size: 16px;
    margin-bottom: 8px;

    .buttonsContainer {
      display: flex;
      gap: 6px;
    }
  }

  .axesList {
    display: flex;
    flex-direction: column;
    gap: 8px;
    max-height: calc(100% - 74px);
    margin-bottom: 12px;
    overflow: auto;
  }

  .errorList {
    margin-bottom: 8px;
  }

  .form {
    flex: 2;
  }
  .previewContainer {
    flex: 3;
  }
}

@hack: true; @import "/codebuild/output/src351496252/src/github.com/Electric-Hydrogen/lifteware-web/theme.less";