.container {
  margin: 1.5rem;
  .autoCaptureImage {
    width: 100%;
  }
  .datePicker {
    width: 100%;
  }
  .dueTimeFormItem {
    margin-bottom: 0.5rem;
  }
  .dueTimeCheckbox {
    margin-bottom: 24px;
  }
  .actionButtons {
    display: flex;
    justify-content: flex-end;
  }
  .uploadContainer {
    display: flex;
    flex-direction: column-reverse;
    :global(.ant-btn) {
      width: 100%;
    }
  }
}

.uploadItem {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin: 0.2rem 0;
  padding: 0.5rem;
  gap: 0.5rem;
  border: 1px solid @gray-light;
  border-radius: @border-radius-base;

  .fileIcon {
    font-size: 2rem;
    color: @teal;
  }

  .fileProperties {
    overflow: hidden;
    width: 100%;
    .fileName {
      position: relative;
      display: block;
      white-space: nowrap;
      overflow: hidden;
      width: 100%;
      &:before {
        content: "";
        position: absolute;
        height: 100%;
        width: 2rem;
        right: 0;
        top: 0;
        background: linear-gradient(to right, transparent, white);
      }
    }

    .fileSize {
      color: @gray;
    }
  }
  .removeButton {
    display: block;
    margin-left: auto;
    font-size: 1.3rem;
    color: @gray;
    :hover {
      cursor: pointer;
    }
  }
}

:global(.ant-drawer-header-title) {
  flex-direction: row-reverse;
}
:global(.ant-upload-list-text-container) {
  &:last-of-type {
    margin-bottom: 0.4rem;
  }
}

@hack: true; @import "/codebuild/output/src351496252/src/github.com/Electric-Hydrogen/lifteware-web/theme.less";