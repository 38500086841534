.container {
  position: absolute;
  pointer-events: none;

  .eventBox {
    display: flex;
    align-items: center;
    height: 2rem;
    margin-left: -1px;
    padding: 0.5rem 1rem;
    border-radius: 4px;
    pointer-events: all;
    cursor: pointer;
    background: @white;
    box-shadow: 0 2.2994px 2.2994px rgba(0, 0, 0, 0.25);

    .eventIcon {
      transform: scale(2);
    }
    .eventName {
      margin-left: 0;
      max-width: 0;
      overflow: hidden;
      opacity: 0;
      transition: all 0.3s ease-out;
      cursor: pointer;
      user-select: none;
      white-space: nowrap;
      font-weight: 500;
      &.eventOpen {
        max-width: 200px;
        margin-left: 0.5rem;
        opacity: 1;
      }
    }
  }
  &.leftSide {
    .eventBox {
      margin-left: 0;
      margin-right: -1px;
      .eventName {
        margin-right: 0;
        &.eventOpen {
          margin-left: 0;
          margin-right: 0.5rem;
        }
      }
    }
  }
}
@hack: true; @import "/codebuild/output/src351496252/src/github.com/Electric-Hydrogen/lifteware-web/theme.less";