@import "/theme.less";

.suggestionsButton {
  display: flex !important;
  align-items: center;
  gap: 8px;
  padding-left: 8px;
  margin-bottom: 12px;
}

.suggestionPlaceholder {
  color: @text-color-secondary;
  padding: 4px 0;
  box-shadow: 0px 2px 0px rgba(0, 0, 0, 0.016);
}

@hack: true; @import "/codebuild/output/src351496252/src/github.com/Electric-Hydrogen/lifteware-web/theme.less";