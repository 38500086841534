.formHeader {
  display: flex;
  flex-direction: column;
  gap: 16px;

  .backButton {
    width: min-content;
    :global(.anticon) {
      padding-right: 4px;
    }
  }

  .title {
    font-size: 24px;
    font-weight: 500;
  }
}

.container {
  display: flex;
  flex-direction: column;
  gap: 12px;
  width: clamp(800px, 90%, 1950px);
  max-width: 100%;
  padding: 20px 0;
  margin: auto;

  .buttonsContainer {
    display: flex;
    justify-content: flex-end;
    margin-top: 1.75rem;
    gap: 8px;
  }
}

.resultIcon {
  color: @teal !important;
}

:global {
  .ant-picker {
    width: 100%;
  }
}

@hack: true; @import "/codebuild/output/src351496252/src/github.com/Electric-Hydrogen/lifteware-web/theme.less";