.tos {
  text-align: justify;
}

.layout {
  min-height: 100vh;
}

.content {
  padding: 16px;
  flex: 1;
}

.card {
  height: 100%;
}

@hack: true; @import "/codebuild/output/src351496252/src/github.com/Electric-Hydrogen/lifteware-web/theme.less";