@import "/theme.less";

.toolbar {
  display: flex;
  align-items: center;
  padding: 2px 0;
  gap: 12px;

  .sortButton {
    color: @teal !important;
  }
}

@hack: true; @import "/codebuild/output/src351496252/src/github.com/Electric-Hydrogen/lifteware-web/theme.less";