@import "/theme.less";

.container {
  width: 100%;
  position: relative;
  display: flex;
  flex-direction: column;

  .bannerText {
    position: relative;
    padding: 1.25rem 1.5rem 0.5rem 1.5rem;
    color: white;

    z-index: 3;
    @media screen and (max-width: @screen-xs) {
      padding: 1rem;
      font-size: 26px;
    }

    .bannerTitle {
      font-weight: 500;
      font-size: 30px;
    }
    .bannerSubtitle {
      padding-top: 0.75rem;
      font-size: 16px;
      font-weight: 700;
    }
  }
  .topBanner {
    position: absolute;
    left: 0;
    right: 0;
    height: 11.625rem;
    overflow: hidden;
    margin-bottom: -5.625rem;
    z-index: 0;

    background-size: cover;
    border-radius: 0rem 0rem 1rem 1rem;
  }

  .notificationsContainer {
    border-radius: 2px;
    height: 100%;
    padding: 12px;
    margin: 24px;
    margin-top: 12px;
    z-index: 2;
    background-color: white;
  }
}

@hack: true; @import "/codebuild/output/src351496252/src/github.com/Electric-Hydrogen/lifteware-web/theme.less";