@import "/theme.less";

.tabs {
    height: 100%;
  :global(.ant-tabs-nav-wrap) {
    background-color: #faf9f6;
    border-radius: 4px;
    padding: 0 8px;
    box-shadow: @shadow-lg;
  }
  :global {
    .ant-tabs-tabpane {
        height: 100%;
    }
    .ant-tabs-content {
        height: 100%;
    }
    .ant-tabs-content-top {
        height: 100%;
    }
  }
}

@hack: true; @import "/codebuild/output/src351496252/src/github.com/Electric-Hydrogen/lifteware-web/theme.less";