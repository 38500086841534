@import "/theme.less";

.modalTitle {
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
}

.titleContainer {
  padding: 16px 24px;
  background-color: white;
  font-size: 16px;
  font-weight: 700;
  border-radius: 4px;
}

.contentContainer {
  background: #f3f2ef;
  padding: 24px;
  border-radius: 4px;
  .cards {
    display: grid;
    grid-template-columns: repeat(8, 1fr);
    gap: 16px;
    @media (max-width: @screen-xxl) {
      grid-template-columns: repeat(4, 1fr);
      .card {
        :global(.ant-card-head-title) {
          padding: 10px 0;
        }
        :global(.ant-card-head) {
          min-height: 34px;
        }
        .cardItem {
          margin-bottom: 6px;
        }
        .cardValue {
          font-size: 16px;
        }
      }
    }
  }

  .card {
    border-radius: 4px;
    box-shadow: 0px 1px 4px -4px rgba(0, 0, 0, 0.1), 0px 1px 20px 0px rgba(0, 0, 0, 0.1);

    .cardContent {
      display: flex;
      flex-direction: column;
    }

    .cardItem {
      display: flex;
      justify-content: space-between;
      font-size: 16px;
      margin-bottom: 16px;
    }

    .cardValue {
      font-size: 18px;
      font-weight: 700;
      color: @teal;
    }
  }
}

.modal {
  :global(.ant-modal-content) {
    padding: 0;
  }
  :global(.ant-modal-header) {
    margin: 0;
  }
}

@hack: true; @import "/codebuild/output/src351496252/src/github.com/Electric-Hydrogen/lifteware-web/theme.less";