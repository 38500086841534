@import "/theme.less";

.searchTreeContainer {
  position: relative;
  width: 60ch;
  z-index: 2;
}

.treeDropdown {
  min-height: 40px;
  position: absolute;
  top: 100%;
  left: 0;
  right: 0;
  background: white;
  border-radius: 4px;
  border: 1px solid #ddd;
  max-height: 300px;
  overflow-y: auto;
  z-index: 1000;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.15);
  &.hidden {
    display: none;
  }
}

.spinContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 8px;
}

.empty {
  margin: 8px;
}

.searchValue {
  color: @teal;
}

@hack: true; @import "/codebuild/output/src351496252/src/github.com/Electric-Hydrogen/lifteware-web/theme.less";