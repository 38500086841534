@import "/theme.less";

.table {
  margin-top: 1rem;
  a {
    color: @text-color;
  }
}

.button {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 6px;
  width: 100%;
  border: 1px dashed @teal;
  border-radius: 4px;
  color: @teal;
}

.modalContent {
  min-height: 240px;;
}
@hack: true; @import "/codebuild/output/src351496252/src/github.com/Electric-Hydrogen/lifteware-web/theme.less";