@import "/theme.less";

.actionsContainer {
  display: flex;
  gap: 0.5rem;
  @media screen and (max-width: @screen-md) {
    flex-direction: column;
    align-items: center;
  }
}

.nameContainer {
  display: flex;
  gap: 0.5rem;
  align-items: center;
  color: @text-color;
  transition: all 0.3s;
  .text {
    min-width: 0;
  }
  .fileIcon {
    transition: all 0.3s;
    font-size: 1.5rem;
    color: @text-light;
  }
  &:hover {
    color: @teal;
    .fileIcon {
      color: @teal;
    }
  }
}

@hack: true; @import "/codebuild/output/src351496252/src/github.com/Electric-Hydrogen/lifteware-web/theme.less";