:global {
  .ant-table-tbody > tr > td.ant-table-cell {
    @media screen and (max-width: @screen-md) {
      padding: 6px;
    }
  }
  .ant-badge {
    white-space: nowrap;
  }
}

@hack: true; @import "/codebuild/output/src351496252/src/github.com/Electric-Hydrogen/lifteware-web/theme.less";