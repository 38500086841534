@media screen and (max-width: @screen-xs) {
  .card {
    padding: 0;
  }
  .table {
    padding: 0 0.3rem;
  }
  .headerContainer,
  .newDataPartnerContainer {
    margin: 0 1rem;
  }
}

.headerContainer {
  display: flex;
  align-items: flex-end;
  justify-content: space-between;

  .organizationName {
    font-weight: 500;
  }
  .newDataPartnerContainer {
    margin-bottom: 1rem;

    .newDataPartnerButtonIcon {
      margin-right: 0.5rem;
    }
  }
}
.dataPartnersContainer {
  margin-top: 1rem;
  background: white;

  filter: drop-shadow(0px 1px 20px rgba(0, 0, 0, 0.1));
  border-radius: 2px;

  .tabs {
    :global(.ant-tabs-nav) {
      margin: 0 !important;
      &::before {
        border: 0 !important;
      }
    }
    :global(.ant-tabs-tab) {
      padding: 0.7rem 1rem !important;
      border: 0 !important;
      border-radius: 0 !important;
      background: @white !important;
      color: @text-light;
    }
    :global(.ant-tabs-tab.ant-tabs-tab-active) {
      background: #fafafa !important;
    }
    :global(.ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn) {
      color: @text-color !important;
    }
    :global(.ant-pagination) {
      display: flex;
      justify-content: center;
    }
  }
  .organizationAvatarName {
    white-space: nowrap;
    .organizationAvatar {
      margin-right: 0.5rem;
    }
  }
}

@hack: true; @import "/codebuild/output/src351496252/src/github.com/Electric-Hydrogen/lifteware-web/theme.less";