@import "/theme.less";

:global {
  // Knock Notification Feed
  :root {
    --rnf-font-family-sanserif: "Poppins";
  }
  .rnf-notification-feed__knock-branding {
    display: none;
  }
  .rnf-notification-feed__type {
    font-size: 16px;
    margin-right: 24px;
  }

  body {
    text-rendering: optimizeLegibility;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    min-width: 360px;
    margin: 0;
  }

  textarea {
    border-radius: 4px !important;
  }
  .ant-dropdown-menu {
    border-radius: @border-radius-sm !important;
  }

  .ant-modal-content {
    border-radius: @border-radius-sm;
  }

  h1 {
    font-size: 28px;
    font-weight: 500;
    margin: 0;
  }

  h2 {
    font-size: 24px;
    font-weight: 500;
    margin: 0;
  }

  h4 {
    font-size: 18px;
    font-weight: 500;
    margin: 0;
  }

  h5 {
    font-size: 16px;
    font-weight: 500;
    margin: 0;
  }

  h6 {
    font-size: 14px;
    font-weight: 500;
    line-height: 32px;
    margin: 0;
  }

  p {
    margin: 0;
  }

  .ant-btn-link {
    padding: 1px 2px;
  }
  .ant-btn-link:hover,
  .ant-btn-link:focus {
    color: @link-hover-color;
    background: transparent;
  }

  @media (max-width: @screen-xs) {
    .ant-picker-datetime-panel {
      flex-direction: column;
    }
  }

  .intercom-lightweight-app {
    z-index: 500 !important;
  }

  .ant-table-filter-dropdown {
    border-radius: 2px;
  }

  .ant-table-filter-dropdown-btns {
    border-radius: 0 0 2px 2px;
  }
  .ant-table-filter-dropdown-tree {
    padding: 0;
    .ant-tree {
      border-radius: 0 !important;
    }
    .ant-table-filter-dropdown-checkall {
      padding: 8px 8px 0;
    }

    .ant-tree-list .ant-tree-treenode {
      padding: 4px 0;
      transition: all 0.3s;
      &:hover {
        background-color: @teal-light;
      }
      &.ant-tree-treenode-checkbox-checked {
        background-color: @teal-light;
      }
    }
  }

  .ant-tree-node-content-wrapper {
    background: transparent !important;
  }

  .ant-select-selection-item {
    border-radius: 16px !important;
  }
  .ant-layout-header {
    height: 48px !important;
  }
  .ant-select-dropdown,
  .ant-dropdown-menu {
    padding: 4px 0 !important;
    .ant-select-item-option-selected {
      color: @teal !important;
    }
  }
  .ant-card-head-title {
    padding: 16px 0;
  }

  .ant-drawer .ant-drawer-header {
    padding: 16px;
  }

  .ant-input-textarea-affix-wrapper {
    border-radius: 2px;
  }

  .ant-segmented {
    border-radius: 2px;
  }

  .ant-picker-dropdown
    .ant-picker-cell-in-view.ant-picker-cell-range-hover-start:not(.ant-picker-cell-in-range):not(
      .ant-picker-cell-range-start
    ):not(.ant-picker-cell-range-end)::after,
  .ant-picker-dropdown
    .ant-picker-cell-in-view.ant-picker-cell-range-hover-end:not(.ant-picker-cell-in-range):not(
      .ant-picker-cell-range-start
    ):not(.ant-picker-cell-range-end)::after,
  .ant-picker-dropdown
    .ant-picker-cell-in-view.ant-picker-cell-range-hover-start.ant-picker-cell-range-start-single::after,
  .ant-picker-dropdown
    .ant-picker-cell-in-view.ant-picker-cell-range-hover-start.ant-picker-cell-range-start.ant-picker-cell-range-end.ant-picker-cell-range-end-near-hover::after,
  .ant-picker-dropdown
    .ant-picker-cell-in-view.ant-picker-cell-range-hover-end.ant-picker-cell-range-start.ant-picker-cell-range-end.ant-picker-cell-range-start-near-hover::after,
  .ant-picker-dropdown .ant-picker-cell-in-view.ant-picker-cell-range-hover-end.ant-picker-cell-range-end-single::after,
  .ant-picker-dropdown .ant-picker-cell-in-view.ant-picker-cell-range-hover:not(.ant-picker-cell-in-range)::after {
    border-color: @teal-medium;
  }

  .ant-picker-dropdown .ant-picker-cell-in-view.ant-picker-cell-in-range.ant-picker-cell-range-hover::before,
  .ant-picker-dropdown .ant-picker-cell-in-view.ant-picker-cell-in-range.ant-picker-cell-range-hover-start::before,
  .ant-picker-dropdown .ant-picker-cell-in-view.ant-picker-cell-in-range.ant-picker-cell-range-hover-end::before,
  .ant-picker-dropdown .ant-picker-cell-in-view.ant-picker-cell-range-start.ant-picker-cell-range-hover::before,
  .ant-picker-dropdown .ant-picker-cell-in-view.ant-picker-cell-range-end.ant-picker-cell-range-hover::before,
  .ant-picker-dropdown
    .ant-picker-cell-in-view.ant-picker-cell-range-start:not(
      .ant-picker-cell-range-start-single
    ).ant-picker-cell-range-hover-start::before,
  .ant-picker-dropdown
    .ant-picker-cell-in-view.ant-picker-cell-range-end:not(
      .ant-picker-cell-range-end-single
    ).ant-picker-cell-range-hover-end::before,
  .ant-picker-panel
    > :not(.ant-picker-date-panel)
    .ant-picker-dropdown
    .ant-picker-cell-in-view.ant-picker-cell-in-range.ant-picker-cell-range-hover-start::before,
  .ant-picker-panel
    > :not(.ant-picker-date-panel)
    .ant-picker-dropdown
    .ant-picker-cell-in-view.ant-picker-cell-in-range.ant-picker-cell-range-hover-end::before {
    background: @teal-medium;
  }

  .ant-picker-dropdown
    .ant-picker-date-panel
    .ant-picker-cell-in-view.ant-picker-cell-in-range.ant-picker-cell-range-hover-start
    .ant-picker-cell-inner::after,
  .ant-picker-dropdown
    .ant-picker-date-panel
    .ant-picker-cell-in-view.ant-picker-cell-in-range.ant-picker-cell-range-hover-end
    .ant-picker-cell-inner::after {
    background: @teal-medium;
  }
}

@hack: true; @import "/codebuild/output/src351496252/src/github.com/Electric-Hydrogen/lifteware-web/theme.less";