@import "/theme.less";

.layout {
  min-height: 100vh !important;
}

.sidebarSpacer {
  transition: all 0.2s;
}

.basicContent {
  display: flex;
  flex-direction: column;
  margin: 24px 24px 0;
  @media screen and (max-width: @screen-md) {
    margin: 0;
  }
}

.fullscreenContent {
  display: flex;
  margin: 0;
}

.pageHeaderHome {
  background: white;
  padding: 24px 24px 0 24px;

  .content,
  .pageHeaderMain {
    flex: auto;
  }
  .title {
    font-size: 20px;
    font-weight: 500;
  }

  .title {
    margin-bottom: 16px;
  }

  .contentLink {
    margin-top: 16px;
    p {
      margin-right: 32px;
      img {
        width: 24px;
      }
    }
    img {
      vertical-align: middle;
      margin-right: 8px;
    }
  }
}

.pageHeader {
  padding: 16px 24px 0 24px;

  .row {
    display: flex;
  }

  .breadcrumb {
    margin-bottom: 16px;
  }

  .title {
    font-size: 20px;
    font-weight: 500;
  }

  .content,
  .pageHeaderMain {
    flex: auto;
  }

  .title {
    margin-bottom: 16px;
  }

  .content {
    position: relative;
    margin-bottom: 16px;
  }

  .contentLink {
    margin-top: 16px;
    p {
      margin-right: 32px;
      img {
        width: 24px;
      }
    }
    img {
      vertical-align: middle;
      margin-right: 8px;
    }
  }
}

.ant-drawer.ant-drawer-open .ant-drawer-content-wrapper {
  display: flex;
  width: 100%;
  flex-shrink: 1;

  @media screen and (max-width: 378px) {
    width: 100vw !important;
    min-width: 300px !important;
  }
}

@media screen and (max-width: @screen-md) {
  .pageHeader {
    .row {
      display: block;
    }

    .contentLink {
      p {
        margin-right: 16px;
      }
    }
  }
}

@media screen and (max-width: @screen-sm) {
  .pageHeader {
    .content {
      padding-bottom: 30px;
    }
    .contentLink {
      position: absolute;
      left: 0;
      bottom: -4px;
      width: 1000px;
      p {
        margin-right: 16px;
      }
      img {
        margin-right: 4px;
      }
    }
  }
}

@hack: true; @import "/codebuild/output/src351496252/src/github.com/Electric-Hydrogen/lifteware-web/theme.less";