.container {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0.5rem 1rem;
  border: 1px solid @teal-medium;
  border-radius: 4px;
  box-shadow: 0 1px 4px -4px rgba(0, 0, 0, 0.1), 0px 1px 20px rgba(0, 0, 0, 0.1);

  .title {
    margin: 0 0 0.5rem;
    font-size: 1.15rem;
    font-weight: 500;
    text-align: center;
    color: @teal;
  }
  .numberContainer {
    text-align: center;

    .number {
      font-size: 2.15rem;
      font-weight: 500;
    }
    .unit {
      margin: 0 0 0 0.25rem;
      font-size: 1rem;
      font-weight: 400;
    }
  }
}

@hack: true; @import "/codebuild/output/src351496252/src/github.com/Electric-Hydrogen/lifteware-web/theme.less";