@import "./theme.less";

.boxesContainer {
  display: flex;
  flex-direction: row;
  gap: 8px;
  z-index: 2;
  margin: 0 110px;
  padding-top: 12px;

  .upperBox {
    flex: 1;
    border-radius: 2px;
    box-shadow: 0px 1px 4px -4px rgba(0, 0, 0, 0.1), 0px 1px 20px 0px rgba(0, 0, 0, 0.1);
    border: 2px solid @teal;
    padding: 8px 16px;
    margin: 0 10px;
    font-weight: 500;
    font-size: 14px;
  }
}

@hack: true; @import "/codebuild/output/src351496252/src/github.com/Electric-Hydrogen/lifteware-web/theme.less";