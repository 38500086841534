@import "/theme.less";

.container {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: calc(100vh - 48px);
  min-height: 753px;
}

.toolbar {
  height: fit-content;
  padding: 8px 24px;
  border-bottom: 1px solid #d9d9d9;
  background: #faf9f6;
  box-shadow: 0px 1px 4px -4px rgba(0, 0, 0, 0.1), 0px 1px 20px 0px rgba(0, 0, 0, 0.1);

  .upperRow {
    display: flex;
    justify-content: space-between;

    .select {
      width: 36ch;
      :global(.ant-select-selector) {
        border-radius: 2px;
      }
    }
  }
  .kpiRow {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    padding-top: 8px;

    .kpiCard {
      display: flex;
      flex-direction: row;
      border-radius: 4px;
      padding: 4px 16px;
      background: white;
      box-shadow: 0px 1px 4px -4px rgba(0, 0, 0, 0.1), 0px 1px 20px 0px rgba(0, 0, 0, 0.1);
      .kpiName {
        display: inline-block;
        font-weight: 600;
        padding-right: 8px;
      }
      .kpiVal {
        display: flex;
        align-items: center;
        line-height: 16px;
        font-size: 16px;
        font-weight: 500;
        color: @teal;
        padding-right: 2px;
      }
    }
  }
}
.content {
  width: 100%;
  flex: 1;
  padding: 8px 24px;
  overflow: scroll;
}

.rangePicker {
  :global(.ant-picker.ant-picker-range) {
    border-radius: 2px;
  }
}

@hack: true; @import "/codebuild/output/src351496252/src/github.com/Electric-Hydrogen/lifteware-web/theme.less";