.subtitle {
  font-size: 14px;
  font-weight: 400;
  line-height: 1.2;
  text-wrap: nowrap;
  @media screen and (min-width: @screen-xxl) {
    font-size: 19px;
  }
  align-self: flex-start;
  min-width: min-content;
}

.singleChartContainer {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  cursor: pointer;
  &:hover {
    .subtitle {
      color: @teal;
    }
  }
}

.doubleChartContainer {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  flex: 1;
  height: 100%;
  cursor: pointer;
  &:hover {
    .subtitle {
      color: @teal;
    }
  }
}

.chartContainer {
  display: flex;
  width: 100%;
  height: 100%;
  justify-content: space-between;
  align-items: center;
  gap: 32px;
  &.vertical {
    flex-direction: column;
    align-items: flex-start;
  }
}

.numberDisplay {
  display: flex;
  align-items: flex-end;
  gap: 4px;
  .number {
    font-size: 34px;
    font-weight: 500;
    line-height: 32px;
    color: @teal;
    @media screen and (min-width: @screen-xxl) {
      font-size: 50px;
      line-height: 48px;
    }
  }
  .unit {
    font-size: 14px;
    font-weight: 500;
    text-wrap: nowrap;
    @media screen and (min-width: @screen-xxl) {
      font-size: 18px;
    }
  }
}

.trendContainer {
  display: flex;
  width: max-content;
  height: fit-content;
  white-space: nowrap;
  padding: 0 6px;
  align-items: center;
  gap: 8px;
  border-radius: 20px;
  border: 1px solid @teal;
  color: @teal;
  background: @teal-light;
  font-size: 12px;
}

.rowSeparated {
  display: flex;
  width: 100%;
  gap: 16px;
  justify-content: space-between;
}

@hack: true; @import "/codebuild/output/src351496252/src/github.com/Electric-Hydrogen/lifteware-web/theme.less";