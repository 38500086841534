@import "/theme.less";

.annotationListContainer {
  flex-direction: column;
  flex: 1;
  overflow-y: scroll;

  .annotationListHeader {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: 1rem;
    border-bottom: 1px solid @bg-light;

    .closeAnnotation {
      font-size: 1rem;
      color: @gray;
      cursor: pointer;
    }
  }
  .annotationListFilters {
    display: grid;
    grid-template-columns: 60% 40%;
    align-items: center;
    padding: 0.5rem 1.5rem;
    gap: 1rem;
  }
  .sortContainer {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 1.25rem;
    .sortButton {
      &:hover, &:global(.ant-dropdown-open) {
        color: @teal;
      }
    }
  }
  .annotationList {
    height: calc(100% - 105px) !important;
    overflow-y: scroll;

    .noAnnotations {
      display: flex;
      height: 100%;
      flex-direction: column;
      justify-content: center;
      align-items: center;
    }
  }
}

:global(.ant-dropdown-menu-item-group-list) {
  margin: 0 !important;
}

@hack: true; @import "/codebuild/output/src351496252/src/github.com/Electric-Hydrogen/lifteware-web/theme.less";