@import "/theme.less";

.container {
  padding: 1.5rem;

  .header {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    gap: 0.5rem;
    .headerButton {
      display: flex;
      align-items: center;
      .icon {
        height: 20px;
      }
    }
    .burgerIcon {
      font-size: 1.2rem;
    }
    :global(.ant-btn) {
      display: flex;
      :global(.anticon) {
        display: flex;
        align-items: center;
      }
    }
  }
  .assetNameSwitchContainer {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 1rem;
    .assetName {
      display: flex;
      align-items: center;
      gap: 1rem;
      margin-left: 0.12rem;
      svg {
        width: 24px;
        height: 24px;
      }
      .tag {
        font-size: 12px;
        .sharedBy {
          color: @text-color-secondary;
        }
      }
    }
    .switchButtons {
      display: flex;
      align-items: center;
      button {
        border: 1px solid #d9d9d9;
        &:focus {
          color: initial;
          border-color: initial;
        }
        &.active {
          border-color: @teal;
          color: @teal;
          z-index: 1;
        }
        &.switchButtonLeft {
          border-top-right-radius: 0;
          border-bottom-right-radius: 0;
          margin-right: -1px;
        }
        &.switchButtonRight {
          border-top-left-radius: 0;
          border-bottom-left-radius: 0;
        }
      }
    }
  }
  .complementaryInformation {
    display: flex;
    flex-direction: row;
    justify-content: stretch;
    gap: 1rem;
    width: 100%;
    margin-top: 1.5rem;

    @media screen and (max-width: @screen-lg) {
      flex-direction: column;
    }
    .box {
      flex: 1;
      border: 1px solid @teal-medium;
      border-radius: 4px;
    }
  }
  .map {
    height: calc(100vh - 332px);
    margin-top: 1rem;
  }
}
.shiftIcon {
  margin-right: 0.25rem;
}

@hack: true; @import "/codebuild/output/src351496252/src/github.com/Electric-Hydrogen/lifteware-web/theme.less";