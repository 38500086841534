.container {
  display: flex;
  flex-direction: column;
  .title {
    font-size: 1.15rem;
    font-weight: 500;
    text-align: center;
    color: @teal;
  }
  .plot {
    flex: 1;
  }
}

@hack: true; @import "/codebuild/output/src351496252/src/github.com/Electric-Hydrogen/lifteware-web/theme.less";