.formHeader {
  display: flex;
  flex-direction: column;
  gap: 16px;

  .backButton {
    width: min-content;
    :global(.anticon) {
      padding-right: 4px;
    }
  }

  .title {
    font-size: 24px;
    font-weight: 500;
  }
}

.buttonsContainer {
  display: flex;
  gap: 8px;
}

@hack: true; @import "/codebuild/output/src351496252/src/github.com/Electric-Hydrogen/lifteware-web/theme.less";