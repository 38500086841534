.container {
  .header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 1rem;
  }
  .content {
    padding: 0;

    .tabs {
      margin: 1rem;

      .organizationHeader {
        display: flex;
        justify-content: flex-end;
        gap: 0.5rem;
        margin-bottom: 0.5rem;
      }
    }
    .requestLink {
      color: @teal;

      &:hover {
        text-decoration: underline !important;
      }
    }
    .overDue {
      color: red;
    }
    .links {
      display: flex;
      align-items: center;
      gap: 1rem;

      .link {
        display: flex;
        align-items: center;
        gap: 0.25rem;
      }
    }
  }
}

.shiftIcon {
  margin-right: 0.25rem;
}
.buttonCenter {
  display: flex;
  align-items: center;
  gap: 0.25rem;
}

:global(.ant-table-row) {
  td {
    background-clip: padding-box !important;
  }
}

@hack: true; @import "/codebuild/output/src351496252/src/github.com/Electric-Hydrogen/lifteware-web/theme.less";