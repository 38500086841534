@import "/theme.less";

.outerContainer {
  margin: 24px 24px 0;
  width: 96.5%;
  @media screen and (max-width: @screen-md) {
    margin: 0;
  }
}

.container {
  width: 100%;
  height: 100%;
}

.gridContainer {
  background: #fafafa;
  padding: 1rem;
  justify-content: center;
}

.plotHeader {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 0 0 0.5rem;

  .title {
    margin: 0;
  }
}

.card {
  overflow: visible;
  border-radius: 2px;

  .handle {
    position: absolute;
    width: 50%;
    height: 50px;
    top: 0;
    left: 0;
    &:hover {
      cursor: move;
    }
  }
}

.innerCard {
  height: 100%;
}

.headerContainer {
  padding: 6px 10px;

  .timeTools {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 8px;
    padding-top: 16px;

    .switchContainer {
      display: flex;
      align-items: center;
      height: 32px;
      min-width: fit-content;
      padding: 5px 8px;
      background: white;
      border-radius: @border-radius-base;
      box-shadow: 0px 2px 0px rgba(0, 0, 0, 0.016);
      cursor: default;
    }

    .switch {
      margin-left: 8px;
    }
  }
}

.header {
  display: flex;
  align-items: center;
  justify-content: space-between;

  .headerLeft {
    display: flex;
    align-items: center;
    gap: 10px;
    .title {
      font-size: 16px;
      font-weight: 500;
    }
    .iconContainer {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 32px;
      height: 32px;
      background: #ffffff;
      box-shadow: 0px 1px 2px -2px rgba(0, 0, 0, 0.1), 0px 1px 6px rgba(0, 0, 0, 0.1);
      border-radius: 4px;
      font-size: 24px;
      color: @teal;
      cursor: pointer;
    }
  }

  .headerRight {
    display: flex;
    align-items: center;
    gap: 10px;

    .toolTipContent {
      gap: 8px;
      align-items: center;
      font-size: 14px;
      line-height: 1.5;
      font-weight: 500;
    }

    .addButton {
      .plusIcon {
        margin-right: 4px;
      }
    }

    .moreIcon * {
      font-size: 20px;
      color: @text-color-secondary;
    }
  }
}

.spinContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
}

:global(.react-grid-item.react-grid-placeholder) {
  background: #b3dcdc !important;
  opacity: 0.6 !important;
}

@hack: true; @import "/codebuild/output/src351496252/src/github.com/Electric-Hydrogen/lifteware-web/theme.less";