@import "/theme.less";

.grid {
  height: 100%;
  display: grid;
  padding-bottom: 24px;
  grid-template-columns: 1fr 1fr 1fr;
  grid-template-rows: 1fr 1fr;
  gap: 10px;
}

.card {
  display: flex;
  flex-direction: column;
  border-radius: 2px;
  padding: 16px;
  background-color: #fff;
  box-shadow: 0px 1.075px 4.3px -4.3px rgba(0, 0, 0, 0.1), 0px 1.075px 21.499px 0px rgba(0, 0, 0, 0.1);
  .secondaryTitle {
    font-size: 14px;
    margin-bottom: 16px;
  }

  @media screen and (min-width: @screen-xxl) {
    padding: 24px;
    .secondaryTitle {
      font-size: 18px;
    }
  }

  h2 {
    font-size: 17px;
    font-weight: 500;
    @media screen and (min-width: @screen-xxl) {
      font-size: 18px;
    }
  }

  .subCard {
    display: flex;
    height: 100%;
    flex-direction: column;
    justify-content: space-evenly;
    align-items: center;
    padding: 16px;
    border-radius: 4px;
    border: 1px solid #d9d9d9;
    background: #fff;
    box-shadow: 0px 1px 4px -4px rgba(0, 0, 0, 0.1), 0px 1px 20px 0px rgba(0, 0, 0, 0.1);

    .subCardTitle {
      font-size: 12px;
      text-align: center;
    }
    .subCardValue {
      font-size: 24px;
      font-weight: 500;
      line-height: 32px;
    }
    @media screen and (min-width: @screen-xxl) {
      padding: 18px;
      .subCardTitle {
        font-size: 16px;
        text-align: center;
      }
      .subCardValue {
        font-size: 34px;
        font-weight: 500;
        line-height: 40px;
      }
    }
  }

  .subtitle {
    color: @teal;
    .numberDisplay {
      font-size: 22px;
      @media screen and (min-width: @screen-xl) {
        font-size: 24px;
      }
      @media screen and (min-width: @screen-xxl) {
        font-size: 28px;
      }
    }
    .text {
      font-size: 16px;
      @media screen and (min-width: @screen-xxl) {
        font-size: 20px;
      }
    }
  }

  .list {
    display: flex;
    flex-direction: column;
    gap: 10px;
    padding-top: 12px;
    font-size: 13px;
    @media screen and (min-width: @screen-xl) {
      font-size: 14px;
    }
    @media screen and (min-width: @screen-xxl) {
      font-size: 16px;
    }
    .listItem {
      display: flex;
      justify-content: space-between;
      .property {
        max-width: 80%;
      }
      .value {
        font-weight: 500;
        align-self: flex-end;
      }
    }
  }

  .chartContainer {
    flex: 1;
    width: 100%;
  }
}

@hack: true; @import "/codebuild/output/src351496252/src/github.com/Electric-Hydrogen/lifteware-web/theme.less";