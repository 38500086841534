.annotationHeaderNew {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 1rem;
  border-bottom: 1px solid @bg-light;
  .annotationHeaderTitle {
    margin: 0;
  }
  .closeAnnotation {
    font-size: 1rem;
    color: @gray;
    cursor: pointer;
  }
}
.annotationHeaderContentContainer {
  flex: 0 1 auto;
}
.annotationContent {
  padding: 1rem;

  .annotationParagraph {
    margin: 0.7rem 0 0.3rem;
  }
  .annotationMeasures {
    display: flex;
    flex-wrap: wrap;
    gap: 0.5rem 0.25rem;
  }
}
.actionButtons {
  display: flex;
  justify-content: flex-start;
  gap: 0.5rem;
}
.mentionHighlight {
  background-color: @teal-medium;
}

@hack: true; @import "/codebuild/output/src351496252/src/github.com/Electric-Hydrogen/lifteware-web/theme.less";