@import "/theme.less";

.propertiesContainer {
  display: flex;
  align-items: center;
  flex-direction: row;
  font-size: 0.8rem;
  padding-bottom: 0.75rem;
  p {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    margin-bottom: 0.5rem;
    line-height: 1.2rem;
  }
  .property {
    width: 160px;
    color: @text-light;
    padding: 5px 0;
  }
  .inputContainer {
    position: relative;
    width: calc(100% - 160px);

    :global(.ant-form-item) {
      height: 1.2rem;
      margin-bottom: 1.2rem !important;
    }
    :global(.ant-form-item-explain-error) {
      color: @error-color;
      font-size: 0.7rem;
      cursor: default;
      margin-left: 0.8rem;
    }
    .select {
      width: 100%;
      :global(.ant-select-selection-item) {
        font-size: 0.8rem;
        padding: 4px 0 !important;
        line-height: 1.2rem !important;
      }
      :global(.ant-select-item-option-content) {
        font-size: 0.8rem;
      }
    }
    .tags {
      :global(.anticon-close) {
        margin-top: 3px;
      }
      :global(.ant-select-selection-item) {
        font-size: 0.8rem;
      }
    }
    .input {
      width: 100%;
      margin-bottom: 1.2rem;
    }
    .locationInput {
      width: 100%;
      margin-bottom: 1.2rem;
      padding: 4px 11px;
      border: 1px solid #d9d9d9;
      border-radius: 20px;
      transition: all 0.3s;
      &:focus {
        border-color: #189995;
        box-shadow: 0 0 0 2px rgba(0, 139, 138, 0.2);
        border-right-width: 1px;
        outline: 0;
      }
    }
    input {
      font-size: 0.8rem;
      line-height: 1.2rem;
    }
    textarea.textarea {
      font-size: 0.8rem;
      margin-bottom: 1.2rem;
      padding: 4px 11px;
      line-height: 1.2rem !important;
      text-align: justify;
      border-radius: 20px !important;
    }
  }
}
:global(.ant-select-dropdown) {
  font-size: 0.8rem !important;
}

@hack: true; @import "/codebuild/output/src351496252/src/github.com/Electric-Hydrogen/lifteware-web/theme.less";