@import "/theme.less";

.modal {
  @media screen and (min-width: @screen-xl) {
    transform: scale(1.1);
  }
  @media screen and (min-width: @screen-3xl) {
    transform: scale(1.3);
  }
  :global(.ant-modal-content) {
    padding: 0;
  }
}

.container {
  display: flex;
  flex-direction: row;
  height: 30rem;
  padding: 0;

  .leftSection {
    background-color: @teal;
    @media screen and (max-width: @screen-xs) {
      display: none;
    }

    .leftContent {
      display: flex;
      flex-direction: column;
      height: 100%;
      padding: 1.25rem;

      .titleContainer {
        display: flex;
        flex-direction: row;
        align-items: center;
        color: @white;
        font-size: 1rem;
        font-weight: 500;
        gap: 0.5rem;
      }

      .stepsContainer {
        flex: 1;
        display: flex;
        justify-content: center;
        align-items: center;
        :global {
          .ant-steps-item-title {
            color: @white !important;
            font-size: 0.875rem;
          }
          .ant-steps-item-finish .ant-steps-item-icon {
            background-color: @white !important;
          }
          .ant-steps-vertical > .ant-steps-item .ant-steps-item-icon {
            width: 1.5rem;
            height: 1.5rem;
            background-color: @teal;
            border-color: @white;
            font-size: 0.75rem;
            line-height: 2;
          }
          .ant-steps-vertical > .ant-steps-item-process .ant-steps-item-icon {
            background-color: @white;
            .ant-steps-item-title {
              color: @white;
            }
          }
          .ant-steps-item > .ant-steps-item-container > .ant-steps-item-icon .ant-steps-icon {
            color: @white;
          }
          .ant-steps-item-process > .ant-steps-item-container > .ant-steps-item-icon .ant-steps-icon {
            color: @teal;
          }
          .ant-steps-vertical > .ant-steps-item > .ant-steps-item-container > .ant-steps-item-tail::after {
            width: 1px;
            height: 0.75rem;
          }
          .ant-steps-vertical > .ant-steps-item > .ant-steps-item-container > .ant-steps-item-tail {
            top: -8px;
            left: 0.75rem;
          }
          .ant-steps-vertical > .ant-steps-item .ant-steps-item-title {
            line-height: 1.5rem;
          }
          .ant-steps-item-tail::after {
            background-color: white;
          }
        }
      }
    }
  }
  .rightSection {
    display: flex;
    justify-content: center;
    align-items: center;
    flex: 2;
    background-color: @white;
    .welcomeContainer {
      display: flex;
      flex-direction: column;
      align-items: center;
      gap: 2rem;
      padding: 3.75rem;
      .logo {
        width: 5rem;
        height: auto;
      }
      .welcomeTitle {
        text-align: center;
        font-size: 28px;
        .titleHighlight {
          color: @teal;
          font-weight: 500;
        }
      }
      .subtitle {
        text-align: center;
      }
    }

    .contentContainer {
      display: flex;
      flex-direction: column-reverse;
      height: -webkit-fill-available;
      width: 100%;
      padding: 1.5rem;
      .buttonsContainer {
        display: flex;
        justify-content: space-between;
        .buttonsRightContainer {
          display: flex;
          gap: 0.375rem;
          .skipButton {
            color: @teal;
            font-weight: 500;
          }
        }
        .backButton {
          font-weight: 500;
          color: @text-light;
        }
      }
      .content {
        display: flex;
        flex-direction: column;
        flex: 1;
        justify-content: center;
        align-items: center;
        .mainSection {
          width: 20rem;
        }
        .centeredSection {
          display: flex;
          flex-direction: column;
          align-items: center;
          width: 20rem;
        }
        .contentTitle {
          font-size: 1.25rem;
          text-align: center;
        }
        .inputContainer {
          margin-top: 1.5rem;
          height: 2rem;
        }
        .contentSubtitle {
          font-size: 0.875rem;
          margin-top: 0.25rem;
        }
        .uploadSection {
          display: flex;
          justify-content: center;
          margin-top: 1.5rem;
          width: 100%;
          .profileImageContainer {
            width: 8rem;
            height: 8rem;
          }
        }
        .smileIcon {
          font-size: 70px;
          margin-bottom: 1.25rem;
          color: @teal;
        }
      }
    }
  }
}

@hack: true; @import "/codebuild/output/src351496252/src/github.com/Electric-Hydrogen/lifteware-web/theme.less";