.container {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;

  &:hover {
    .image {
      opacity: 50%;
      transform: scale(95%);
      border-radius: 1rem;
    }
    .uploadContainer {
      opacity: 100%;
    }
  }
  .image {
    width: 100%;
    height: auto;
    opacity: 100%;
    transition: all 0.3s;
  }
  .spin {
    position: absolute;
    top: calc(50% - 0.5rem);
    left: calc(50% - 0.5rem);
    :global(.ant-spin-dot-item) {
      background-color: @white !important;
    }
  }
  .input {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    width: 100%;
    opacity: 0;
    cursor: pointer;
  }
  .uploadContainer {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    color: @button-dark;
    font-size: 3rem;
    opacity: 0;
    transition: opacity 0.3s;
  }
}
.imageCropModal {
  width: 630px !important;
}
.avatarEditorContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: auto;
}

@hack: true; @import "/codebuild/output/src351496252/src/github.com/Electric-Hydrogen/lifteware-web/theme.less";