@import "/theme.less";

.card {
  @media screen and (max-width: @screen-md) {
    padding: 12px;
  }
  @media screen and (max-width: @screen-xs) {
    padding: 0;
  }
  .topSection {
    min-width: 100%;
    padding: 0;
    @media screen and (max-width: @screen-xs) {
      padding: 10px;
      padding-bottom: 0;
    }
    .tableTitle {
      font-size: 16px;
      font-weight: 500;
    }
    .tableInfo {
      padding-top: 18px;
    }
  }
  .topContainer {
    display: flex;
    min-width: 100%;
    justify-content: space-between;
    align-items: center;

    .orgInfo {
      display: flex;
      align-items: center;
      gap: 16px;
      font-weight: 500;
      font-size: 24px;

      .selectOrg {
        :global(.ant-select-selection-item) {
          font-weight: 500;
          font-size: 24px;
        }
      }
    }
  }
  :global(.ant-tabs-nav) {
    @media screen and (max-width: @screen-xs) {
      padding-left: 10px;
    }
  }
}
.orgSelectDropdown {
  width: min-content !important;
}

@hack: true; @import "/codebuild/output/src351496252/src/github.com/Electric-Hydrogen/lifteware-web/theme.less";