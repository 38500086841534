.container {
  .header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 1rem;
  }

  .toolbar {
    display: flex;
    justify-content: space-between;

    .tabs {
      display: flex;
      gap: 4px;

      .tab {
        color: @text-color;
        background: #fff;
        padding: 9px 16px;
        border-radius: 4px;
        cursor: pointer;
        box-shadow: 0px 1px 4px -4px rgba(0, 0, 0, 0.1), 0px 1px 20px 0px rgba(0, 0, 0, 0.1);
        &.selected {
          color: @teal;
        }
      }
    }
  }
  
  .content {
    padding: 0;

    .filters {
      display: flex;
      align-items: center;
      justify-content: space-between;
      gap: 1rem;
      padding: 1rem;
      div {
        display: flex;
        align-items: center;
        gap: 1rem;
      }
      .treeSelect {
        width: 300px;
      }
      .statusSelector {
        width: 200px;
      }
    }
    .requestLink {
      color: @teal;

      &:hover {
        text-decoration: underline !important;
      }
    }
    .overDue {
      color: red;
    }
    .links {
      display: flex;
      align-items: center;
      gap: 1rem;

      .link {
        display: flex;
        align-items: center;
        gap: 0.25rem;
      }
    }
  }
}

.shiftIcon {
  margin-right: 0.25rem;
}
.notDefined {
  color: @gray;
}

:global {
  .ant-table-row {
    td {
      background-clip: padding-box !important;
    }
  }
  .ant-picker-dropdown.ant-picker-dropdown-placement-topLeft:has(.ant-picker-datetime-panel) {
    left: auto !important;
    right: 10px !important;
  }
}

@hack: true; @import "/codebuild/output/src351496252/src/github.com/Electric-Hydrogen/lifteware-web/theme.less";