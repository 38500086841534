@import "/theme.less";

.container {
  display: flex;
  flex-direction: column;
  height: calc(100vh - 72px);
  min-height: 750px;
  min-width: 1190px;
  max-width: 100%;
  gap: 16px;
}

.subtitle {
  font-size: 14px;
  font-weight: 400;
  line-height: 1.2;
  text-wrap: nowrap;
  @media screen and (min-width: @screen-xxl) {
    font-size: 19px;
    &.modesSubtitle {
      font-size: 16px;
    }
  }
}

.title {
  font-size: 16px;
  font-weight: 700;
}

.topContainer {
  display: flex;
  justify-content: space-between;

  .modesContainer {
    display: flex;
    gap: 16px;
    padding: 8px 16px;
    background: #fff;
    border-radius: 20px;
    box-shadow: 0px 1px 4px -4px rgba(0, 0, 0, 0.1), 0px 1px 20px 0px rgba(0, 0, 0, 0.1);

    .modeOption {
      display: flex;
      align-items: center;
      padding: 2px 8px;
      background: #f0f0f0;
      color: @text-color-secondary;
      border-radius: 4px;

      &.selectedMode {
        background: #e5f1f1;
        color: @eh2-green;
      }
    }
  }
  .button {
    height: 38px;
    padding: 5px 16px;
  }
}

.upperCharts {
  display: flex;
  gap: 24px;
  flex: 5;

  .plotContainer {
    width: 100%;
    height: 100%;
  }
}

.card {
  display: flex;
  flex-direction: column;
  align-items: center;
  flex: 1;
  padding: 12px;
  background: #fff;
  border-radius: 4px;
  box-sizing: border-box;
  box-shadow: 0px 1px 4px -4px rgba(0, 0, 0, 0.1), 0px 1px 20px 0px rgba(0, 0, 0, 0.1);

  .cardHeader {
    display: flex;
    width: 100%;
    justify-content: space-between;
  }

  @media screen and (min-width: 1900px) {
    padding: 16px 24px;
  }

  .subtitle {
    align-self: flex-start;
    min-width: min-content;
  }
}

.stacksIntervalSelector {
  width: 15ch;
  :global(.ant-select-selector) {
    border-radius: 20px !important;
  }
}

.botSectionHeader {
  display: flex;
  justify-content: space-between;

  .selectors {
    display: flex;
    gap: 8px;

    .refreshSelector {
      width: 22ch;
    }
    .intervalSelector {
      width: 16ch;
    }
    .circleButton {
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }
}

.bottomCharts {
  flex: 8;
  width: 100%;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-template-rows: repeat(3, auto);
  grid-template-areas:
    "A B C"
    "D E F"
    "G H I";
  padding-bottom: 16px;
  gap: 10px;
}

.rowSeparated {
  display: flex;
  width: 100%;
  gap: 16px;
  justify-content: space-between;
}

@hack: true; @import "/codebuild/output/src351496252/src/github.com/Electric-Hydrogen/lifteware-web/theme.less";