@import "/theme.less";

.metadataContainer {
  display: flex;
  flex-direction: row;
  gap: 3rem;
  padding: 1rem;

  .assetImage {
    flex: 1;
    max-width: 400px;
    .image {
      box-shadow: 0 2px 2px rgba(0, 0, 0, 0.02), 0 4px 16px rgba(0, 32, 51, 0.12);
      border-radius: 4px;
      width: 100%;
    }
  }
  .metadata {
    flex: 2;
  }
  @media screen and (max-width: @screen-lg) {
    flex-direction: column-reverse;
    align-items: center;
  }
}

@hack: true; @import "/codebuild/output/src351496252/src/github.com/Electric-Hydrogen/lifteware-web/theme.less";