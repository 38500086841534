.container {
  width: 100%;
  margin-top: 2rem;
  background: white;
  box-shadow: 0 1px 4px -4px rgba(0, 0, 0, 0.1), 0px 1px 20px rgba(0, 0, 0, 0.1);
  border-radius: 2px;

  .tabs {
    :global(.ant-tabs-nav) {
      margin: 0 !important;
      &::before {
        border: 0 !important;
      }
    }
    :global(.ant-tabs-tab) {
      padding: 0.7rem 1rem !important;
      border: 0 !important;
      border-radius: 0 !important;
      background: @white !important;
      color: @text-light;
    }
    :global(.ant-tabs-tab.ant-tabs-tab-active) {
      background: #fafafa !important;
    }
    :global(.ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn) {
      color: @text-color !important;
    }
    :global(.ant-pagination) {
      display: flex;
      justify-content: center;
    }
  }
}

@hack: true; @import "/codebuild/output/src351496252/src/github.com/Electric-Hydrogen/lifteware-web/theme.less";