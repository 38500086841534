@import "/theme.less";

@gap: 20px;
@profile-picture-size: 128px;
@icon-badge-size: 32px;
@icon-size: 18px;

.grid {
  display: grid;
  grid-template-areas:
    "info user-card"
    "info user-card";
  grid-template-columns: 2fr 1fr;
  grid-template-rows: 1fr 1fr;
  gap: @gap;
  height: calc(100vh - @gap - @gap - @layout-header-height);
  min-height: 700px;

  @media screen and (max-width: @screen-md) {
    height: auto;
    display: flex;
    flex-direction: column;
  }
}

.profileInfo {
  grid-area: info;
  overflow: auto;
  :global .ant-card-body {
    padding: 10px 28px;
  }
}

.userCard {
  grid-area: user-card;
  text-align: center;
  position: relative;
  overflow: hidden;

  @media screen and (max-width: @screen-lg) {
    min-height: 300px;
  }

  :global {
    .ant-card-head {
      background-image: url("https://images.unsplash.com/photo-1505118380757-91f5f5632de0?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=400&q=80");
      background-size: cover;
      height: 35%;
      min-height: 100px;
    }

    .ant-card-body {
      height: 100%;
    }
  }

  .cardContent {
    padding-top: calc((@profile-picture-size / 2) - @card-padding-base + 20px);
    position: relative;
    .username {
      font-size: 16px;
      @media screen and (min-width: @screen-xl) {
        font-size: 18px;
      }
      font-weight: 500;
    }
  }
}

.pictureContainer {
  position: absolute;
  width: @profile-picture-size;
  height: @profile-picture-size;
  display: flex;
  justify-content: center;
  align-items: center;
  top: 0 - @card-padding-base - (@profile-picture-size / 2);
  left: calc(50% - (@profile-picture-size / 2));

  .editIconContainer {
    position: absolute;
    cursor: pointer;
    z-index: 99;
    right: 0;
    top: 0;
    background-color: @white;
    width: @icon-badge-size;
    height: @icon-badge-size;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: @icon-size;
    box-shadow: @shadow;
    &:hover {
      box-shadow: @shadow-md;
      background-color: rgb(252, 252, 252);
      transform: scale(1.05);
    }
  }
  .avatar {
    height: 100%;
    width: 100%;
  }
  .avatarPlaceholder {
    transform: scale(4.2);
  }

  .avatarLoading {
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 40px !important;
  }
}

.organizationImage {
  width: 160px;
  height: 160px;
  border-radius: 50%;
  overflow: hidden;

  img {
    width: 100% !important;
    height: 100% !important;
    object-fit: cover;
  }
}
.container {
  padding: 1rem 0;
}
.form {
  width: 100%;
  &:global(.ant-form) {
    margin: 0 !important;
  }
}
.formSection {
  margin-top: 0;
}
.settingsItem:not(:first-of-type) .formSection {
  margin-top: 2rem;
}
.profileItem {
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  margin: 0 0 1rem 0;

  .infoIcon {
    margin-left: 0.5rem;
    font-size: 0.9rem;
  }
  .property {
    display: flex;
    align-items: center;
    flex: 0 1 20ch;
    font-size: 16px;
    font-weight: 500;
    margin: 0 !important;
  }
  .value {
    font-size: 14px;
    flex: 1;
  }
  .input {
    font-size: 14px;
    flex: 1;
    min-width: 24ch;
  }
}

.avatarEditorContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.settingsItem {
  display: flex;
  h5 {
    flex: 0 1 20ch;
  }
}

@hack: true; @import "/codebuild/output/src351496252/src/github.com/Electric-Hydrogen/lifteware-web/theme.less";