.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  .headerRight {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    gap: 0.5rem;
    .headerButton {
      display: flex;
      align-items: center;
    }
    :global(.ant-btn) {
      display: flex;
      :global(.anticon) {
        display: flex;
        align-items: center;
      }
    }
  }
}

.shiftIcon {
  margin-right: 0.25rem;
}

@hack: true; @import "/codebuild/output/src351496252/src/github.com/Electric-Hydrogen/lifteware-web/theme.less";