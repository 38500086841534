@import "/theme.less";


.segmentedContainer {
  :global {
    .ant-segmented {
      margin-bottom: 12px;
      min-width: 440px;
      max-width: 1200px;
    }

    .ant-segmented-thumb,
    .ant-segmented-item-selected {
      background-color: @teal !important;
    }
  }
}

.roleCard {
  min-width: fit-content;
  h4 {
    color: @bg-dark;
    font-weight: 700;
    transition: color ease-in-out 0.3s;
  }
  display: flex;
  flex-direction: column;
  white-space: normal;
  height: 200px;
  padding: 14px;
  .roleDescriptionContainer {
    flex: 1;
    display: flex;
    align-items: center;
  }
  transition: color ease-in-out 0.3s;
  &&.selected {
    color: @white;
    h4 {
      color: @white;
    }
  }
  &&:not(.selected) {
    color: @text-color;
  }
  @media screen and (max-width: @screen-sm) {
    padding: 4px 0;
    h4 {
      font-size: 16px;
    }
    .roleDescriptionContainer {
      font-size: 12px;
    }
  }
}

@hack: true; @import "/codebuild/output/src351496252/src/github.com/Electric-Hydrogen/lifteware-web/theme.less";