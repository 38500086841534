@import "/theme.less";

:global(.ant-form) {
  margin-top: 2rem !important;
}
.container {
  padding: 1.5rem;
}
.backActionContainer {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-bottom: 1rem;
  .actionButtons {
    display: flex;
    align-items: center;
    margin-left: auto;
    gap: 0.5rem;
  }
}
.backActionContainer {
  display: flex;

  .actionButtonsNormal {
    margin-left: auto;
  }
}
.form {
  margin-top: 1rem !important;
}
.editTitleContainer {
  display: flex;
  align-items: center;
  flex: 1;
  margin-bottom: 1rem;

  .titleFormItem {
    margin: 0;
    width: 100%;
  }
  input.editTitle {
    flex: 1;
    padding: 0 0.5rem;
    border-radius: 4px;
    font-size: 1.5rem;
    font-weight: 500;
    caret-color: @teal;
  }
  input.editTitle:hover,
  input.editTitle:focus {
    border-color: transparent;
    box-shadow: none;
    background: @teal-light;
  }
  input.editTitle:focus {
    cursor: text;
  }
  .editTitleButton {
    padding: 4px !important;
    svg {
      font-size: 1.5rem;
      color: @teal;
    }
  }
}
.metadataContainer {
  display: flex;
  flex-direction: row;
  gap: 3rem;
  margin-top: 1rem;
  .assetImage {
    flex: 1;
    max-width: 400px;
    .image {
      box-shadow: 0 2px 2px rgba(0, 0, 0, 0.02), 0 4px 16px rgba(0, 32, 51, 0.12);
      border-radius: 4px;
      width: 100%;
    }
  }
  .metadata {
    flex: 2;
    .assetName {
      margin-bottom: 2rem;
    }
  }
  @media screen and (max-width: @screen-lg) {
    flex-direction: column-reverse;
    align-items: center;
  }
}

@hack: true; @import "/codebuild/output/src351496252/src/github.com/Electric-Hydrogen/lifteware-web/theme.less";